<template>
    <div class="container_content">
        <Wizard ref="refWizard" :isQuoting="true" @showRegisterModal="showModal('register')" @alert="showAlert" type="quoting" mode="quoting"/>
        <component
            :is="currentModal.component"
            :show="currentModal.show"
            v-if="currentModal"
            @showModal="showModal($event)"
            @save="showModal(), $refs.refWizard.save()"
            @close="currentModal.show = false"
        />
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import Wizard from '@/components/item-wizard/wizard'
import { notify } from "@kyvg/vue3-notification"
import RegisterModal from '@/components/auth/modals/RegisterModal'
import LoginModal from '@/components/auth/modals/LoginModal'
import ForgotPasswordModal from '@/components/auth/modals/ForgotPasswordModal'

export default({
    components: {
        Wizard,
        RegisterModal,
        LoginModal,
        ForgotPasswordModal
    },
    setup() {
        if(sessionStorage.getItem('wizardParams')) sessionStorage.removeItem('wizardParams')

        const refWizard = ref(null)

        const modals = ref([
            {
                show: false,
                component: 'LoginModal',
                key: 'login',
            },
            {
                show: false,
                component: 'RegisterModal',
                key: 'register',
            },
            {
                show: false,
                component: 'ForgotPasswordModal',
                key: 'forgot',
            },
        ])

        const currentModal = computed(() => modals.value.find(el => el.show))

        function showModal(modal) {
            modals.value.forEach(el => {
                el.show = false
            })

            if(modal) {
                 setTimeout(() => {
                    const modalFromArray = modals.value.find(el => el.key == modal)
                    if(modalFromArray) modalFromArray.show = true
                }, 500)
            }
        }

        function showAlert(alert) {
            notify({
                type: 'success',
                title: alert
            })
        }


        return {
            showModal,
            refWizard,
            currentModal,
            showAlert
        }
    },
})
</script>

<style scoped>
@media (max-width: 768px) {
  .container_content, .container_content_content {
    min-height: auto !important;
   /* height: 100vh !important;*/
   /* padding: 0 !important;*/
  }
}
</style>
